import { Box, Button, Flex, Link, Text } from '@chakra-ui/react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Credentials } from '~/api/auth'
import { useAuth } from '~/auth'

import { LabeledInput } from '@smallworld-io/pangaea'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import useDocumentTitle from '~/hooks/useDocumentTitle'
import useRedirect from './redirect'

export const Login = () => {
  const navigate = useNavigate()
  const redirect = useRedirect()
  const { onAuth, isLoggedIn, loginError, onLogin } = useAuth()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = useForm<Credentials>()

  useEffect(() => {
    onAuth()
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      redirect.from('login')
    }
  }, [isLoggedIn, redirect])

  useDocumentTitle('Log in to your Super Connector Account – SmallWorld')

  const submitHandler: SubmitHandler<Credentials> = ({ login, password }) => {
    onLogin({ login, password })
  }

  return (
    <Flex height="100vh" justify="center" align="center">
      <Box width="100%" bg="blue.400" borderColor="blue.700" borderWidth="2px" p={4} mb={4} textAlign="center">
        <Text color="white" fontWeight="bold">
          Looking for Relationship Leads? Click this{' '}
          <Link color="blue.500" href="https://app.smallworld.ai/people/sign_out" textDecoration="underline">
            link
          </Link>{' '}
          and reauthenticate using your SmallWorld Enterprise credentials.
        </Text>
      </Box>
      <Flex direction="column" minW="md">
        <Text as="h1" fontSize="4xl" pb={4} display="block" textAlign="center">
          Log in
        </Text>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box pb={4}>
            <Controller
              name="login"
              control={control}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                <LabeledInput
                  label="Login"
                  ref={ref}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={invalid}
                  data-hj-allow
                />
              )}
            />
          </Box>
          <Box pb={4}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                <LabeledInput
                  type="password"
                  label="Password"
                  ref={ref}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={invalid}
                />
              )}
            />
          </Box>
          <Box pb={2}>
            <Button variant="primary" type="submit" isLoading={isSubmitting}>
              Log In
            </Button>
          </Box>
          {loginError && (
            <Box pb={2} textAlign={'center'}>
              {loginError.statusCode === 403 ? (
                <Text color="red">
                  Sorry! There was an issue. Please click this{' '}
                  <Link color="blue.500" href="https://app.smallworld.ai/people/sign_out">
                    link
                  </Link>{' '}
                  and reauthenticate using your SmallWorld Enterprise credentials.
                </Text>
              ) : (
                <Text color="red">Sorry, that login and password combination was incorrect</Text>
              )}
            </Box>
          )}

          <Box pb={4}>
            <Link fontSize="xs" display="block" textAlign="center" onClick={() => navigate('/password-reset')}>
              Forgot password?
            </Link>
          </Box>
        </form>
      </Flex>
    </Flex>
  )
}
